// This file is compiled inside Decidim core pack. Code can be added here and will be executed
// as part of that pack

// Load images
require.context("../../images", true)

$(document).ready(function() {
  const $form = $("#register-form");
  var $signupRadioButtons = $("input[name='user[signup_as]']");
  const dynamicFieldsContainer = document.getElementById('signup_as');

  const name = $.trim($("input[name='user[name]']").val());
  // Remove whitespace.
  $('#registration_user_name').on('keyup', function() {
      var inputValue = $(this).val();
      var trimmedValue = inputValue.replace(/^\s+/, '');
      $(this).val(trimmedValue);
  });
  
  function toggleOrganizationNameField() {
    const signup_as_error = document.querySelector('.signup-as-error');
    if (signup_as_error) {
      signup_as_error.remove();
    }
    var selectedValue = $signupRadioButtons.filter(':checked').val();

    if (selectedValue === "organization" || selectedValue === "องค์กร") {
      const inputGroup = document.createElement('div');
      inputGroup.className = 'input-group org-name';
      const label = document.createElement('label');
      label.htmlFor = 'registration_user_organization_name';
      label.innerHTML = getLabelName()+'<span title="" data-tooltip="true" data-disable-hover="false" data-keep-on-hover="true" class="label-required has-tip" aria-describedby="7a3h4q-tooltip" data-yeti-box="7a3h4q-tooltip" data-toggle="7a3h4q-tooltip" data-resize="7a3h4q-tooltip" id="toggle-m01pm" aria-controls="7a3h4q-tooltip"><span aria-hidden="true">*</span><span class="sr-only">Required field</span></span>';
      label.id = 'label_organization_name';

      const input = document.createElement('input');
      input.required = 'required';
      input.type = 'text';
      input.name = 'user[organization_name]';
      input.id = 'registration_user_organization_name';

      input.addEventListener('input', function() {
        // Remove existing error message when user types
        const existingError = inputGroup.querySelector('.org-name-error');
        if (existingError) {
          existingError.remove();
          input.classList.remove('is-invalid-input')
        }
      });

      inputGroup.appendChild(label);
      label.appendChild(input);
      dynamicFieldsContainer.after(inputGroup);

    } else {
      $('.org-name').remove();
    }
  }

  function validateForm(event) {
    const existingError = $('.org-name-error');
    const signup_as_error = $('.signup-as-error');
    const location_error = $('.location-error');
    
    if (signup_as_error) {
      signup_as_error.remove();
    }

    if (location_error) {
      location_error.remove();
    }

    if (existingError) {
      existingError.remove();
    }
    var selectedRadio = document.querySelector("input[name='user[signup_as]']:checked");
    if (!selectedRadio) {
      event.preventDefault();
      const span = document.createElement('span');
      span.className = 'form-error is-visible signup-as-error';
      span.role = 'alert';
      span.textContent = getErrorMsg();
      span.style.display = 'block'
      $('#signup_as_organization').after(span)
      $('#signup_as_องค์กร').after(span)
    }
    var selectedValue = $signupRadioButtons.filter(':checked').val();
    const organizationInput = document.getElementById('registration_user_organization_name');
    // registration_user_signup_as_organization
    if ((selectedValue === "organization" || selectedValue === "องค์กร")) {
      if (organizationInput.value.trim() === ""){
        event.preventDefault(); // Prevent form submission
        const span = document.createElement('span');
        span.className = 'form-error is-visible org-name-error';
        span.role = 'alert';
        span.textContent = getErrorMsg();
        span.style.display = 'block'
        
        organizationInput.after(span);
        organizationInput.classList.add('is-invalid-input')
      }
    }

    const name = $.trim($("input[name='user[name]']").val());
    // Remove whitespace.
    $('#registration_user_name').on('keyup', function() {
        var inputValue = $(this).val();
        var trimmedValue = inputValue.replace(/^\s+/, '');
        $(this).val(trimmedValue);
    });

    // Trigger error message for name field.
    if(name.length > 0){
      if(!name.match(' ')){
        const organizationInput = document.getElementById('registration_user_name');
        const input = document.createElement('input');
        input.required = 'required';
        input.type = 'text';
        input.name = 'user[name]';
        input.id = 'registration_user_name';

        const locale = new URLSearchParams(window.location.search).get('locale');
        let orgLabel = '';

        if (locale == 'th' || locale == null) {
          errorMsg = 'ป้อนชื่อเต็ม';
        } else {
          errorMsg = 'Enter full name';
        }

        event.preventDefault(); // Prevent form submission
        const span = document.createElement('span');
        span.className = 'form-error is-visible org-name-error';
        span.role = 'alert';
        span.textContent = errorMsg;
        span.style.display = 'block'
        
        organizationInput.after(span);
        organizationInput.classList.add('is-invalid-input');
      }
    }

    const checkbox = document.getElementById("accept-policy-checkbox-signup");
    const errorMessage = document.getElementById("error-message-signup");

    if (!checkbox.checked) {
      event.preventDefault(); // Prevent form submission
      errorMessage.style.display = "block"; // Show error message
    } else {
      errorMessage.style.display = "none"; // Hide error message
    }

    const locationSelect = document.getElementById("registration_user_location");
    const locationBox = document.getElementById("other_location");

    if (locationSelect.value === "Other" || locationSelect.value === 'อื่นๆ') {
      if (locationBox.value.trim() === ""){
          event.preventDefault(); // Prevent form submission
          const span = document.createElement('span');
          span.className = 'form-error is-visible location-error';
          span.role = 'alert';
          span.textContent = getErrorMsg();
          span.style.display = 'block'
          
          locationBox.after(span);
          locationBox.classList.add('is-invalid-input')
      }  
    }
  }

  function getLabelName(){
    const locale = new URLSearchParams(window.location.search).get('locale');
    let orgLabel = '';

    if (locale == 'th' || locale == null) {
      orgLabel = 'ชื่อองค์กร';
    } else {
      orgLabel = 'Organization Name';
    }
    return orgLabel;
  }

  function getErrorMsg(){
    const locale = new URLSearchParams(window.location.search).get('locale');
    let errorMsg = '';

    if (locale == 'th' || locale == null) {
      errorMsg = 'มีข้อผิดพลาดในฟิลด์นี้';
    } else {
      errorMsg = 'There is an error in this field.';
    }
    return errorMsg;
  }

  $signupRadioButtons.change(toggleOrganizationNameField);
  toggleOrganizationNameField(); // Call on page load to handle preselected value

  $form.on('submit', validateForm);

  const locationSelect = document.getElementById("registration_user_location");

  if (locationSelect) {
    locationSelect.addEventListener("change", function() {
      if (locationSelect.value === "Other" || locationSelect.value === 'อื่นๆ') {
        createLocationField();
      } else {
        $('#other-location-wrapper').remove();
      }
    });
  }
  function createLocationField(){
    const dynamicFieldsContainer = document.getElementById('select-location');

    const inputGroup = document.createElement('div');
    inputGroup.className = 'field';
    inputGroup.id = 'other-location-wrapper';

    const label = document.createElement('label');
    label.htmlFor = 'other_location';
    label.innerHTML = getLocationLabelName()+'<span title="" data-tooltip="true" data-disable-hover="false" data-keep-on-hover="true" class="label-required has-tip" aria-describedby="7a3h4q-tooltip" data-yeti-box="7a3h4q-tooltip" data-toggle="7a3h4q-tooltip" data-resize="7a3h4q-tooltip" id="toggle-m01pm" aria-controls="7a3h4q-tooltip"><span aria-hidden="true">*</span><span class="sr-only">Required field</span></span>';
    label.id = 'label_organization_name';

    const input = document.createElement('input');
    input.required = 'required';
    input.type = 'text';
    input.id = 'other_location';
    input.name = 'user[location]';
    input.style = 'margin: 2px;';

    input.addEventListener('input', function() {
      // Remove existing error message when user types
      const existingError = inputGroup.querySelector('.location-error');
      if (existingError) {
        existingError.remove();
        input.classList.remove('is-invalid-input')
      }
    });

    inputGroup.appendChild(label);
    label.appendChild(input);
    dynamicFieldsContainer.after(inputGroup);
  }

  function getLocationLabelName(){
    const locale = new URLSearchParams(window.location.search).get('locale');
    let orgLabel = '';

    if (locale == 'th' || locale == null) {
      orgLabel = 'ระบุสถานที่';
    } else {
      orgLabel = 'Specify Location';
    }
    return orgLabel;
  }
});
